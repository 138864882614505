import * as React from "react";
import Button from "react-bootstrap/Button";

const CancelOrder = () => {
  const queryString = typeof window !== "undefined" ? window.location.href : "";
  const uri = new URLSearchParams(queryString);

  if (uri.has("form")) {
    var form = uri.get("form");
    if (form === "ipc-2021-purchase") {
      return (
        <>
          <h3>
            Your Integral Practitioner Convergence 2021 Event Order has been
            canceled.
          </h3>
          <p>
            <Button
              variant="primary"
              href="/public-programs/workshops-and-events/integral-practitioner-convergence-2021"
              className="button"
            >
              Go Back
            </Button>
          </p>
        </>
      );
    }
    if (form === "ipc-2022-purchase") {
      return (
        <>
          <h3>
            Your Integral Practitioner Convergence 2022 Event Order has been
            canceled.
          </h3>
          <p>
            <Button
              variant="primary"
              href="/public-programs/workshops-and-events/integral-practitioner-convergence-2021"
              className="button"
            >
              Go Back
            </Button>
          </p>
        </>
      );
    }
    if (form === "idtl-public-course") {
      return (
        <>
          <h3>
            Your Integral Development and Transformative Leadership Public
            Course Order has been canceled.
          </h3>
          <p>
            <Button
              variant="primary"
              href="/public-programs/online-courses/integral-development-and-transformative-leadership"
              className="button"
            >
              Go Back
            </Button>
          </p>
        </>
      );
    }
    if (form === "rpvc-public-course") {
      return (
        <>
          <h3>
            Your Reimagining Power and Valuing Care Public Course Order has been
            canceled.
          </h3>
          <p>
            <Button
              variant="primary"
              href="/public-programs/online-courses/reimagining-power-and-valuing-care"
              className="button"
            >
              Go Back
            </Button>
          </p>
        </>
      );
    }
    if (form === "tct-fa2021-public-course") {
      return (
        <>
          <h3>
            Your Transforming Collective Trauma Public Course Order has been
            canceled.
          </h3>
          <p>
            <Button
              href="/public-programs/online-courses/transforming-collective-trauma"
              className="button"
            >
              Go Back
            </Button>
          </p>
        </>
      );
    }
    if (form === "tdatd-fa2021-public-course") {
      return (
        <>
          <h3>
            Your The Dancer and The Dance Public Course Order has been canceled.
          </h3>
          <p>
            <a
              href="/public-programs/online-courses/the-dancer-and-the-dance"
              className="button"
            >
              Go Back
            </a>
          </p>
        </>
      );
    }
    if (form === "cict-online-course") {
      return (
        <>
          <h3>
            Your Changes in Collective Trauma Public Course Order has been
            canceled.
          </h3>
          <p>
            <Button
              href="/public-programs/online-courses/changes-in-collective-trauma"
              className="button"
            >
              Go Back
            </Button>
          </p>
        </>
      );
    } else {
      return (
        <>
          <p>Your order has been cancelled.</p>
        </>
      );
    }
  } else {
    return (
      <>
        <p>Your order has been cancelled.</p>
      </>
    );
  }
};

export default CancelOrder;
